































































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class kineantropia_card extends Vue {}
